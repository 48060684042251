.template-login {
  .container-background {
    background-color: #2b303b;
    height: 100vh;
    display: flex;

    .img-background {
      width: 100%;
      margin: auto;
    }
  }

  .logo {
    width: 115px;
  }

  .main-login{
    background-color: #FFFFFF;
  }
}

body {
  background-color: #F2F4F6;
}

@for $i from 0 through 100 {
  //positivos
  .m-l-#{$i} {
    margin-left: #{$i}px !important;
  }
  .m-r-#{$i} {
    margin-right: #{$i}px !important;
  }
  .m-t-#{$i} {
    margin-top: #{$i}px !important;
  }
  .m-b-#{$i} {
    margin-bottom: #{$i}px !important;
  }
  .m-#{$i} {
    margin: #{$i}px;
  }
  .m-#{$i}i {
    margin: #{$i}px !important;
  }

  //negativos
  .m-l--#{$i} {
    margin-left: -#{$i}px !important;
  }
  .m-r--#{$i} {
    margin-right: -#{$i}px !important;
  }
  .m-t--#{$i} {
    margin-top: -#{$i}px !important;
  }
  .m-b--#{$i} {
    margin-bottom: -#{$i}px !important;
  }
  .m--#{$i} {
    margin: -#{$i}px;
  }

  //paddings
  .p-l-#{$i} {
    padding-left: #{$i}px;
  }
  .p-r-#{$i} {
    padding-right: #{$i}px;
  }
  .p-t-#{$i} {
    padding-top: #{$i}px;
  }
  .p-b-#{$i} {
    padding-bottom: #{$i}px;
  }
  .p-l-#{$i}i {
    padding-left: #{$i}px !important;
  }
  .p-r-#{$i}i {
    padding-right: #{$i}px !important;
  }
  .p-t-#{$i}i {
    padding-top: #{$i}px !important;
  }
  .p-b-#{$i}i {
    padding-bottom: #{$i}px !important;
  }
  .p-#{$i} {
    padding: #{$i}px;
  }
  .p-#{$i}i {
    padding: #{$i}px !important;
  }
}

.mlr-0 {
  margin-left: 0;
  margin-right: 0;
}

.cursor {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.segoe-regular {
  font-family: 'Segoe UI Regular', serif;
}

.segoe-italic {
  font-family: 'Segoe UI Italic', serif;
}

.segoe-bold {
  font-family: 'Segoe UI Bold', serif;
}

.segoe-bold-italic {
  font-family: 'Segoe UI Bold Italic', serif;
}

.segoe-semibold {
  font-family: 'Segoe UI Semibold', serif;
}

body {
  font-family: 'Segoe UI Regular', serif;
}

.rs-icon {
  &.rs-icon-edit, &.rs-icon-trash2 {
    cursor: pointer;
    margin: 0 3px;
  }
}

.color-primary-varian {
  color: $color-primary-variant;
}

.MuiButtonBase-root {
  &.MuiButton-containedPrimary {
    color: $color-text-primary;
    background-color: $color-primary;

    &:hover {
      background-color: $color-primary-hover;
    }

    &.Mui-disabled {
      color: rgba(0, 0, 0, 0.26) !important;
      box-shadow: none !important;
      background-color: rgba(0, 0, 0, 0.12) !important;
    }
  }

  &.Mui-disabled {
    cursor: not-allowed !important;
    pointer-events: auto !important;
  }
}

.position-relative {
  position: relative;
}

.pac-container {
  z-index: 2000;
}

.MuiTooltip-popper {
  .MuiTooltip-tooltip {
    background-color: #272c36;

    .MuiTooltip-arrow {
      color: #272c36;
    }
  }
}

#search-address {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  width: 250px;
  border-radius: 4px;

  &:focus, &:hover {
    border: 1px solid $color-primary;
  }
}

.audit-type-task {
  .loader-map-task {
    position: absolute;
    left: 10px;
    top: 10px;
    z-index: 100;

    .rs-loader-spin {
      &:after {
        border-color: $color-primary transparent transparent !important;
      }
    }
  }
}

.panel-place-detail {
  .rs-panel-heading {
    padding-bottom: 0;

    .place-type, .place-name {
      color: #4D4F5C;
    }
  }

  .img-audit {
    width: 100%;
  }

  .place-detail-img-empty {
    background-color: #F3F3F3;
    min-height: 300px;

    img{
      margin-top: 94px;
    }
  }
}

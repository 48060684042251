.menu {
  .menu-title {
    color: #FFFFFF;
  }

  .menu-link {
    text-align: center;

    a {
      text-decoration: none;
      display: inline-block;

      .menu-text {
        width: 23px;
        height: 23px;
        background-color: #21252D;
        margin: auto;
        cursor: pointer;
        text-align: center;
        transition: 0.2s;
        color: #788195;
        text-decoration: none;

        &.active, &:hover {
          background-color: $color-primary;
          color: $color-text-primary;
        }
      }
    }
  }

  @media (min-width: 1367px) {
    .menu-title {
      font-size: 16px;
    }
    .menu-link {
      a {
        .menu-text {
          width: 32px;
          height: 32px;
          font-size: 16px;
          line-height: 30px;

          .rs-icon{
            font-size: 18px;
            line-height: 30px;
          }
        }
      }
    }
  }
}

.sidebar{
  background-color: #2B303B;
  position: fixed;
  width: 66px;
  height: 100vh;

  @media (min-width: 1367px) {
    width: 82px;
  }
}

.rs-table-custom {
  &.table-places-types {
    .rs-table-cell-content {
      padding: 6px 10px;
      display: table-cell;
      vertical-align: middle;
    }

    .icon-place-type {
      width: 45px;
      height: 45px;
      border-radius: 50%;
    }
  }
}

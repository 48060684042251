.rs-drawer {
  .rs-drawer-dialog {
    .rs-drawer-content {
      .rs-drawer-header {
        .rs-drawer-title {
          font-family: 'Segoe UI Bold', serif;
          font-weight: normal;
        }
      }

      .rs-drawer-body {
        scroll-behavior: smooth;
      }
    }
  }
}

.rs-uploader {
  display: block;

  &.required {
    .rs-uploader-trigger-btn {
      &:after {
        content: '*';
        position: absolute;
        top: 10px;
        right: 10px;
        color: #ff0000;
      }
    }
  }

  .rs-uploader-trigger {
    width: 100%;

    .rs-uploader-trigger-btn {
      display: block;
      width: 100%;
      height: auto;
      padding-bottom: 20px;
      border: 2px dotted #dedede;
      margin: 0;
      position: relative;
      border-radius: 0;

      label.rs-control-label {
        margin: 9px 13px 0 13px;
        display: block;
        text-align: left;
        color: #575757;
      }

      > div {
        margin: 15px 0 10px 0;
      }
    }
  }
}

.rs-panel {
  background-color: #FFFFFF;
  border-radius: 0;

  .rs-panel-heading {
    color: $color-primary-variant;
  }

  &.rs-panel-custom {
    min-height: calc(100vh - 104px);
  }
}

.rs-table {
  .rs-table-cell-header-icon-sort {
    &, &:after {
      color: $color-primary-variant !important;
      width: 12px;
      height: 12px;
    }
  }

  .rs-table-body-info {
    &.loader {
      height: 100%;
      top: 0;
    }
  }

  .rs-table-cell-content {
    .rs-icon {
      cursor: pointer;
      margin: 0 3px;
      color: #575757;

      &.disabled{
        color: #a0a0a0;
        cursor: not-allowed;
      }
    }
  }
}

.rs-table-pagination-toolbar {
  justify-content: center;

  .rs-table-pagination-start {
    display: none;
  }
}

.rs-avatar {
  background-color: $color-primary;
  color: $color-text-primary;

  &.avatar-45 {
    width: 45px;
    height: 45px;

    .rs-avatar-image {
      width: 45px;
      height: 45px;
    }
  }
}

.rs-progress {
  &.no-radius {
    .rs-progress-line-outer {
      border-radius: 0;

      .rs-progress-line-inner {
        border-radius: 0;

        .rs-progress-line-bg {
          border-radius: 0;
        }
      }
    }
  }
}

.rs-picker-check {
  .rs-btn {
    &:focus, &:hover {
      border: 1px solid $color-primary !important;
    }
  }
}

.panel-admin {
  padding-left: 66px;

  .contend-admin {
    height: calc(100vh - 104px);
  }

  @media (min-width: 1367px) {
    padding-left: 82px;
  }
}

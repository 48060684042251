.header {
  height: 64px;
  background-color: #FFFFFF;

  .logo {
    width: 74px;
  }

  .avatar{
    margin-right: 30px;
  }
}

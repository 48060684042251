.rs-form {
  font-family: 'Segoe UI Semibold', serif;

  .rs-form-group {
    border: 1px solid #D6D6D6;
    padding: 9px 13px 0 13px;
    margin-bottom: 10px !important;
    display: block;
    position: relative;

    &.required {
      &:after {
        content: "*";
        position: absolute;
        top: 6px;
        right: 10px;
        color: #ff0000;
      }
    }

    &.disabled {
      &, .rs-input, label {
        background: #eee;
        color: rgba(98, 98, 98, 0.23);
        cursor: not-allowed;
      }
    }

    .rs-input {
      border: none;
      padding-top: 0;
      padding-left: 0;
      width: 100%;
      font-family: 'Segoe UI Regular', serif;
    }

    .rs-form-control-wrapper {
      display: block;
      width: 100%;

      .rs-error-message-wrapper {
        right: 0;

        .rs-error-message-inner {
          white-space: normal;
        }
      }
    }

    .rs-input-group {
      &.rs-input-number {
        width: auto;
        border: none;
        border-radius: 0;

        .rs-input-number-btn-group-vertical {
          .rs-btn {
            border-radius: 0;
          }
        }
      }
    }
  }

  .rs-btn {
    &.rs-btn-primary {
      color: #000000;
      background-color: $color-primary;
    }

    &.rs-btn-ghost {
      color: #888888;
      border-color: #888888;
    }
  }

  .rs-checkbox {
    .rs-checkbox-wrapper {
      &:before {
        border: 1px solid $color-primary;
      }
    }

    &.rs-checkbox-checked {
      .rs-checkbox-inner {
        &:before {
          background-color: $color-primary;
        }
      }
    }

    .rs-checkbox-checker {
      &:hover {
        .rs-checkbox-wrapper {
          .rs-checkbox-inner {
            &:before {
              border-color: $color-primary;
            }
          }
        }
      }
    }
  }

  .rs-control-label {
    text-transform: uppercase;
  }

  .rs-picker-select {
    .rs-btn {
      padding-top: 0;
      padding-left: 0;

      &:hover, &:active, &:focus {
        background-color: transparent !important;
      }

      .rs-picker-toggle-caret, .rs-picker-toggle-clean {
        top: 0;
      }

      .rs-picker-toggle-caret {
        right: 0;
      }

      .rs-picker-toggle-clean {
        right: 18px;
      }

      .rs-picker-toggle-value {
        color: #575757;
      }
    }
  }

  .square-color {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 3px 6px 0 0;
    float: left;
  }

  .disabled-map {
    background-color: #000000;
    opacity: 0.6;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 100;
  }
}

.rs-picker-select-menu-items {
  .rs-picker-select-menu-item.rs-picker-select-menu-item-active.rs-picker-select-menu-item-focus {
    color: $color-primary;
  }
}

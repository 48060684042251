.zones-status {
  .shop-members-total {
    background-color: #9C80DF;
    border-radius: 6px;
    color: #FFFFFF;

    .text-black {
      color: #000000;
    }

    .text-green {
      color: #67EE7D;
    }

    .title {
      font-size: 12px;
    }

    .content {
      font-size: 18px;

      .shop {
        font-size: 13px;
      }
    }

    .last-route {
      font-size: 12px;
    }
  }

  .reserved-delivery-shop {
    .bold {
      font-weight: bold;

      &.numbers {
        font-size: 17px;
      }
    }

    .text {
      font-size: 12px;
    }
  }

  .distribution-text {
    font-weight: bold;
    font-size: 13px;
  }

  table.table-distribution {
    min-width: auto;

    thead {
      tr {
        th {
          padding: 5px;
          font-weight: normal;
          font-size: 13px;
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 5px;
          font-weight: normal;
          font-size: 12px;

          &.bold {
            font-weight: bold;
          }
        }
      }
    }
  }

  .linear-progress-zone-status {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;

    &.MuiLinearProgress-colorPrimary {
      background-color: transparent;
    }

    .MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary.MuiLinearProgress-bar1Indeterminate,
    .MuiLinearProgress-bar.MuiLinearProgress-bar2Indeterminate.MuiLinearProgress-barColorPrimary {
      background-color: $color-primary;
    }
  }
}
